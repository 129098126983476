import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { supabase } from '../supabaseClient';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';  

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const Account = () => {
    const { user } = useContext(AuthContext);
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
    });
    const [wifiCredentials, setWifiCredentials] = useState([]);
    const [newWifiCredential, setNewWifiCredential] = useState({ ssid: '', password: '' });
    const [deviceSerialNumbers, setDeviceSerialNumbers] = useState([]);  // Add state for device serials
    const [newDeviceSerial, setNewDeviceSerial] = useState('');  // New device serial input
    const [userExists, setUserExists] = useState(false);
    const [showPassword, setShowPassword] = useState({});
    const [apiKey, setApiKey] = useState('');
    const [showApiKey, setShowApiKey] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await supabase.auth.getSession();
                const token = data.session?.access_token;
                const response = await axios.get(`${API_BASE_URL}/users/user-details/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });

                if (response.data.user) {
                    setUserData(response.data.user);
                    setUserExists(true);
                    await fetchWifiCredentials(token);
                    await fetchDeviceSerialNumbers(token);  // Fetch device serials
                } else {
                    console.log('User not found, creating user...');
                    setUserExists(false);
                }
                
                await fetchApiKey(token);
            } catch (error) {
                console.error('Error fetching user data', error);
                setUserExists(false);
            }
        };

        fetchUserData();
    }, []);

    const fetchWifiCredentials = async (token) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/wifi/get-wifi-credentials/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setWifiCredentials(response.data.wifi_credentials || []);
        } catch (error) {
            console.error('Error fetching WiFi credentials', error);
        }
    };

    const fetchDeviceSerialNumbers = async (token) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/device_registry/get-serials/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            // Check if the response contains the expected device_serials array
            if (response.data && response.data.device_serials) {
                setDeviceSerialNumbers(response.data.device_serials);
            } else {
                console.log('No device serials found');
            }
        } catch (error) {
            console.error('Error fetching device serial numbers', error);
        }
    };
    

    const fetchApiKey = async (token) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/auth/get-api-key/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.data && response.data.api_key) {
                setApiKey(response.data.api_key);
            }
        } catch (error) {
            console.error('Error fetching API key', error);
        }
    };

    const handleDeviceSerialInputChange = (e) => {
        setNewDeviceSerial(e.target.value);
    };

    const handleAddDeviceSerial = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
    
            // Validate or format the API key before sending it
            const formattedApiKey = uuidValidate(apiKey) ? apiKey : uuidv4(apiKey); // Ensure it's a valid UUID or generate one
    
            await axios.post(`${API_BASE_URL}/device_registry/register-device/`, { 
                device_serial: newDeviceSerial, 
                api_key: formattedApiKey  // Ensure apiKey is in UUID format
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
    
            setDeviceSerialNumbers([...deviceSerialNumbers, newDeviceSerial]);
            setNewDeviceSerial('');
        } catch (error) {
            console.error('Error adding device serial number', error);
        }
    };
    

    const handleDeleteDeviceSerial = async (serialNumber) => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;

            await axios.delete(`${API_BASE_URL}/device_registry/remove-device/${serialNumber}/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            setDeviceSerialNumbers(deviceSerialNumbers.filter(serial => serial !== serialNumber));
        } catch (error) {
            console.error('Error deleting device serial number', error);
        }
    };

    const handleWifiInputChange = (e) => {
        const { name, value } = e.target;
        setNewWifiCredential({ ...newWifiCredential, [name]: value });
    };

    const handleAddWifiCredential = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.post(`${API_BASE_URL}/wifi/add-wifi-credential/`, newWifiCredential, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            setWifiCredentials([...wifiCredentials, newWifiCredential]);
            setNewWifiCredential({ ssid: '', password: '' });
        } catch (error) {
            console.error('Error adding WiFi credential', error);
        }
    };

    const handleDeleteWifiCredential = async (ssid) => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.delete(`${API_BASE_URL}/wifi/delete-wifi-credential/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: { ssid }
            });
            setWifiCredentials(wifiCredentials.filter(cred => cred.ssid !== ssid));
        } catch (error) {
            console.error('Error deleting WiFi credential', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleUpdateUser = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;

            if (userExists) {
                const response = await axios.put(`${API_BASE_URL}/users/update-user/`, userData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                console.log('Update response:', response);
            } else {
                await handleCreateUser(token);
            }
        } catch (error) {
            console.error('Error updating user data', error);
        }
    };

    const handleCreateUser = async (token) => {
        try {
            const userInfo = {
                first_name: userData.first_name, 
                last_name: userData.last_name, 
                email: userData.email,
                phone_number: userData.phone_number, 
                address_line1: userData.address_line1, 
                address_line2: userData.address_line2,
                city: userData.city,             
                state: userData.state,          
                postal_code: userData.postal_code, 
                country: userData.country 
            };

            await axios.post(`${API_BASE_URL}/users/create-user/`, userInfo, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            setUserData(userInfo);
            setUserExists(true);
            await fetchWifiCredentials(token);
        } catch (error) {
            console.error('Error creating user', error);
        }
    };

    const togglePasswordVisibility = (ssid) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [ssid]: !prevState[ssid],
        }));
    };

    const toggleApiKeyVisibility = () => {
        setShowApiKey(!showApiKey);
    };

    return (
        <div>
            <Typography variant="h5">Account Information</Typography>
            <form>
                <TextField name="first_name" label="First Name" value={userData.first_name} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="last_name" label="Last Name" value={userData.last_name} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="email" label="Email" value={userData.email} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="phone_number" label="Phone Number" value={userData.phone_number} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="address_line1" label="Address Line 1" value={userData.address_line1} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="address_line2" label="Address Line 2" value={userData.address_line2} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="city" label="City" value={userData.city} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="state" label="State" value={userData.state} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="postal_code" label="Postal Code" value={userData.postal_code} onChange={handleInputChange} fullWidth margin="normal" />
                <TextField name="country" label="Country" value={userData.country} onChange={handleInputChange} fullWidth margin="normal" />
                <Button variant="contained" color="primary" onClick={handleUpdateUser}>Update Profile</Button>
            </form>

            <Typography variant="h5" style={{ marginTop: '2rem' }}>API Key</Typography>
            <Typography variant="body1" style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
                {showApiKey ? apiKey : '********'}
                <IconButton edge="end" aria-label="toggle visibility" onClick={toggleApiKeyVisibility}>
                    {showApiKey ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
            </Typography>

            <Typography variant="h5" style={{ marginTop: '2rem' }}>WiFi Credentials</Typography>
            <List>
                {wifiCredentials.map((cred, index) => (
                    <ListItem key={index}>
                        <ListItemText
                            primary={cred.ssid}
                            secondary={showPassword[cred.ssid] ? cred.password : '********'}
                        />
                        <IconButton edge="end" aria-label="toggle visibility" onClick={() => togglePasswordVisibility(cred.ssid)}>
                            {showPassword[cred.ssid] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteWifiCredential(cred.ssid)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            <TextField name="ssid" label="SSID" value={newWifiCredential.ssid} onChange={handleWifiInputChange} fullWidth margin="normal" />
            <TextField name="password" label="Password" value={newWifiCredential.password} onChange={handleWifiInputChange} fullWidth margin="normal" />
            <Button variant="contained" color="primary" onClick={handleAddWifiCredential}>Add WiFi</Button>

            <Typography variant="h5" style={{ marginTop: '2rem' }}>Device Serial Numbers</Typography>
            <List>
                {deviceSerialNumbers.map((serial, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={serial} />
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteDeviceSerial(serial)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            <TextField
                name="device_serial"
                label="Device Serial Number"
                value={newDeviceSerial}
                onChange={handleDeviceSerialInputChange}
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleAddDeviceSerial}>
                Add Device Serial Number
            </Button>
        </div>
    );
};

export default Account;
