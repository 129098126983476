import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { green, grey, blue } from '@mui/material/colors';
import { Container, Typography, Button, AppBar, Toolbar, Tabs, Tab, Box } from '@mui/material';
import Home from './components/Home';
import Talk from './components/Talk';
import Settings from './components/Settings';
import History from './components/History';
import Account from './components/Account';
import AuthPage from './components/AuthPage';
import AuthContext from './context/AuthContext';
import { supabase } from './supabaseClient';

const theme = createTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: blue[300] },
    text: { primary: grey[700], secondary: grey[500] },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h5: { fontWeight: 300, color: grey[700] },
    h6: { fontWeight: 300, color: grey[700] },
    body1: { fontWeight: 300, color: grey[600] },
    button: { fontWeight: 300, color: grey[600] },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('home');

  // Redirect to HTTPS if accessed via HTTP
  useEffect(() => {
    if (window.location.protocol !== 'https:') {
      window.location.href = 'https://' + window.location.hostname + window.location.pathname;
    }
  }, []);

  useEffect(() => {
    const checkAuthState = async () => {
      const { data } = await supabase.auth.getSession();
      setUser(data.session?.user || null);
      setLoading(false);
    };

    checkAuthState();
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
      setLoading(false);
    });

    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ user, setUser }}>
        <Router>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" style={{ flexGrow: 1 }}>EchoBach</Typography>
              {user && (
                <Button color="inherit" onClick={() => supabase.auth.signOut()}>Logout</Button>
              )}
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
            {loading ? (
              <Typography variant="h6">Loading...</Typography>
            ) : user ? (
              <Box>
                <Tabs value={tab} onChange={handleChangeTab} centered>
                  <Tab label="Home" value="home" />
                  <Tab label="Talk" value="talk" />
                  <Tab label="Settings" value="settings" />
                  <Tab label="History" value="history" />
                  <Tab label="Account" value="account" />
                </Tabs>
                {tab === 'home' && <Home />}
                {tab === 'talk' && <Talk />}
                {tab === 'settings' && <Settings />}
                {tab === 'history' && <History />}
                {tab === 'account' && <Account />}
              </Box>
            ) : (
              <Routes>
                <Route path="/" element={<AuthPage />} />
              </Routes>
            )}
          </Container>
        </Router>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
