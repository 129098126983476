// src/components/Home.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const Home = () => {
  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Typography variant="h6" gutterBottom>
        Thank you for trying EchoBach mark I
      </Typography>
      <Typography variant="body1">
        To get started, go to the 'Settings' tab to configure your first assistant. Just write a system prompt and select the tool functions you want to use.
      </Typography>
    </Container>
  );
};

export default Home;
