import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { TextField, Button, Box, Typography, Grid, Switch, FormControlLabel, RadioGroup, FormControlLabel as RadioLabel, Radio } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const defaultPrompt = "You are a helpful assistant.";

const Settings = () => {
    const { user } = useContext(AuthContext);

    // State Declarations
    const [systemPrompt, setSystemPrompt] = useState(defaultPrompt);
    const [assistants, setAssistants] = useState([]);
    const [selectedAssistant, setSelectedAssistant] = useState('');
    const [newAssistantName, setNewAssistantName] = useState('');
    const [newAssistantPrompt, setNewAssistantPrompt] = useState(defaultPrompt);
    const [userName, setUserName] = useState('');
    const [userBirthDate, setUserBirthDate] = useState('');
    const [userGender, setUserGender] = useState('');
    const [tools, setTools] = useState([]);
    const [selectedTools, setSelectedTools] = useState([]);
    const [expandedTool, setExpandedTool] = useState(null); // State to track which tool is expanded
    const [recordingMode, setRecordingMode] = useState('push_to_talk');  // Default

    const backendIconUrl = `${API_BASE_URL}/static/icons/`;  

    // Fetch settings when the component mounts or when the user changes
    useEffect(() => {
        fetchSettings();
        fetchButtonSettings();
    }, [user]);

    // Functions
    const fetchSettings = async () => {
        if (user) {
            try {
                const { data } = await supabase.auth.getSession();
                const token = data.session?.access_token;

                const settingsResponse = await axios.get(`${API_BASE_URL}/interactions/interaction-settings/`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setSystemPrompt(settingsResponse.data.system_prompt);

                await fetchAssistants();

                const toolsResponse = await axios.get(`${API_BASE_URL}/assistant/list_tools/`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setTools(toolsResponse.data.tools);

                const selectionResponse = await axios.get(`${API_BASE_URL}/assistant/get_selected_assistant/`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setSelectedAssistant(selectionResponse.data.assistant_id);
            } catch (error) {
                console.error('Error fetching settings', error);
            }
        }
    };

    const fetchButtonSettings = async () => {
        if (user) {
            try {
                const { data } = await supabase.auth.getSession();
                const token = data.session?.access_token;

                const response = await axios.get(`${API_BASE_URL}/button/button-settings/`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setRecordingMode(response.data.recording_mode);
            } catch (error) {
                console.error('Error fetching button settings', error);
                setRecordingMode('push_to_talk');  // Default
            }
        }
    };

    const handleRecordingModeToggle = async () => {
        const newMode = recordingMode === 'push_to_talk' ? 'toggle' : 'push_to_talk';
        setRecordingMode(newMode);  // Update the UI optimistically
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.post(`${API_BASE_URL}/button/button-settings/`, {
                recording_mode: newMode,
            }, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            console.log('Recording mode updated successfully');
        } catch (error) {
            console.error('Error updating recording mode', error);
            alert('Error updating recording mode');
        }
    };

    const fetchAssistants = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            const assistantsResponse = await axios.get(`${API_BASE_URL}/assistant/list_assistants_with_tools/`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
    
            setAssistants(assistantsResponse.data);
        } catch (error) {
            console.error('Error fetching assistants', error);
        }
    };

    const createNewAssistant = async () => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;

            await axios.post(`${API_BASE_URL}/assistant/create_assistant_with_tools/`, {
                assistant: {  
                    assistant_name: newAssistantName,
                    assistant_system_prompt: newAssistantPrompt,
                    user_name: userName,
                    birth_date: userBirthDate ? new Date(userBirthDate).toISOString().split('T')[0] : null,
                    gender: userGender,
                },
                tools: selectedTools
            }, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            alert('Assistant created successfully');
            await fetchAssistants(); // Refetch assistants after creation
            setNewAssistantName('');
            setNewAssistantPrompt(defaultPrompt);
            setUserName('');
            setUserBirthDate('');
            setUserGender('');
            setSelectedTools([]);
            setExpandedTool(null); // Reset expanded tool on assistant creation
        } catch (error) {
            console.error('Error creating assistant', error);
            alert('Error creating assistant');
        }
    };

    const deleteAssistant = async (assistant_id) => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.delete(`${API_BASE_URL}/assistant/delete_assistant/${assistant_id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setAssistants(prevAssistants => prevAssistants.filter(assistant => assistant.assistant_id !== assistant_id));
            alert('Assistant deleted successfully');
        } catch (error) {
            console.error('Error deleting assistant', error);
            alert('Error deleting assistant');
        }
    };

    const selectAssistant = async (assistant_id) => {
        try {
            const { data } = await supabase.auth.getSession();
            const token = data.session?.access_token;
            await axios.post(`${API_BASE_URL}/assistant/select_assistant_with_tools/`, { assistant_id }, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setSelectedAssistant(assistant_id);
            await fetchAssistants(); // Refetch assistants after selection
            alert('Assistant selected successfully');
        } catch (error) {
            console.error('Error selecting assistant', error);
            alert('Error selecting assistant');
        }
    };

    const toggleToolSelection = (toolName) => {
        if (selectedTools.includes(toolName)) {
            setSelectedTools(selectedTools.filter(t => t !== toolName));
        } else {
            setSelectedTools([...selectedTools, toolName]);
        }
    };

    // Function to expand or collapse tool tile
    const handleToolClick = (toolName) => {
        setExpandedTool(prevTool => (prevTool === toolName ? null : toolName));
    };

    // Render
    return (
        <Box p={3}>
            {/* Create Custom Assistant */}
            <Typography variant="h6" gutterBottom>
                Create Custom Assistant
            </Typography>
            <TextField
                label="Assistant Name"
                fullWidth
                value={newAssistantName}
                onChange={(e) => setNewAssistantName(e.target.value)}
                margin="normal"
            />

            {/* Tool selection tiles in a grid layout */}
            <Typography variant="h6" gutterBottom mt={4}>
                Select Tools
            </Typography>
            <Grid container rowSpacing={3} columnSpacing={5} justifyContent="space-between"> {/* Increase spacing */}
                {tools.map((tool) => (
                    <Grid item xs={12} sm={4} key={tool.name}> {/* 3 tiles per row on small screens and up */}
                        <Box
                            sx={{
                                padding: 2,
                                textAlign: 'center',
                                backgroundColor: selectedTools.includes(tool.name) ? '#007BFF' : '#A9A9A9',  // Lighter grey for unselected
                                color: 'white',
                                border: selectedTools.includes(tool.name) ? '2px solid #0056b3' : 'none',  // No border for unselected
                                borderRadius: '8px',
                                cursor: 'pointer',
                                transition: '0.3s ease',
                                '&:hover': {
                                    backgroundColor: selectedTools.includes(tool.name) ? '#0056b3' : '#A9A9A9',  // Change hover color
                                },
                                height: expandedTool === tool.name ? 'auto' : '100px',  // Default size
                                width: '90%',  // Make sure tiles take up full width in their grid column
                               
                            }}
                            onClick={() => {
                                toggleToolSelection(tool.name);
                                handleToolClick(tool.name);
                            }}
                        >
                            <img
                                src={`${backendIconUrl}${tool.icon}`}  // Prepend the backend URL to the icon path
                                alt={tool.display_name}  // Alt text should be display_name
                                style={{ width: '50px', height: '50px', objectFit: 'contain', marginBottom: '0.5rem' }}
                            />
                            <Typography variant="subtitle1" sx={{ marginBottom: '0.25rem' }}>{tool.display_name || 'Unnamed Tool'}</Typography>
                            {expandedTool === tool.name && (
                                <Typography variant="body2" sx={{ marginTop: '0.5rem' }}>
                                    {tool.display_description}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>


            {/* System prompt field */}
            <TextField
                label="Assistant System Prompt"
                multiline
                rows={4}
                fullWidth
                value={newAssistantPrompt}
                onChange={(e) => setNewAssistantPrompt(e.target.value)}
                margin="normal"
            />

            {/* Additional user details */}
            <Typography variant="subtitle1" gutterBottom>
                User Details (Optional)
            </Typography>
            <TextField
                label="User's Name"
                fullWidth
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                margin="normal"
            />
            <TextField
                label="User's Birth Date"
                type="date"
                fullWidth
                value={userBirthDate}
                onChange={(e) => setUserBirthDate(e.target.value)}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                label="User's Gender"
                fullWidth
                value={userGender}
                onChange={(e) => setUserGender(e.target.value)}
                margin="normal"
            />

            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={createNewAssistant}
                style={{ marginTop: '1rem' }}
            >
                Create Assistant
            </Button>

            {/* Assistant selection */}
            <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                    Select Custom Assistant
                </Typography>
                <RadioGroup
                    aria-label="select-assistant"
                    value={selectedAssistant}
                    onChange={(e) => selectAssistant(e.target.value)}
                >
                    {assistants.length > 0 ? (
                        assistants.map((assistant) => (
                            <RadioLabel
                                key={assistant.assistant_id}
                                value={assistant.assistant_id}
                                control={<Radio />}
                                label={
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box>
                                            <Typography variant="subtitle1">
                                                {assistant.assistant_name || 'Unnamed Assistant'}
                                            </Typography>
                                            <Typography variant="body2">
                                                {assistant.assistant_system_prompt}
                                            </Typography>
                                            <Typography variant="body2">
                                                Tools: {assistant.assistant_tools ? assistant.assistant_tools.join(', ') : 'None'}
                                            </Typography>
                                        </Box>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => deleteAssistant(assistant.assistant_id)}
                                            style={{ marginLeft: '10px' }}
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                }
                            />
                        ))
                    ) : (
                        <Typography>No assistants available.</Typography>
                    )}
                </RadioGroup>
            </Box>

            {/* Recording Mode Toggle */}
            <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                    Recording Mode
                </Typography>
                <FormControlLabel
                    control={
                        <Switch
                            checked={recordingMode === 'toggle'}
                            onChange={handleRecordingModeToggle}
                            name="recording-mode-toggle"
                            color="primary"
                        />
                    }
                    label={recordingMode === 'push_to_talk' ? 'Push to Talk' : 'Toggle'}
                />
            </Box>
        </Box>
    );
};

export default Settings;
